// useSharepointFiles.js

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { useSelector } from 'react-redux';
import {
  selectIncident,
  selectGroupGuid,
} from '../../../slices/commonSelectors';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import { useState, useEffect } from 'react';

// Define the query key
const keys = {
  sharepointFiles: 'sharepointFiles',
};

// Hook to fetch SharePoint files
export const useSharepointFiles = () => {
  const queryClient = useQueryClient();
  const currentIncident = useSelector(selectIncident);

  // Effect to handle toast and cache based on currentIncident
  useEffect(() => {
    if (!currentIncident) {
      // clear the cache if no incident is present
      queryClient.removeQueries({ queryKey: [keys.sharepointFiles] });
    } else if (
      !currentIncident?.tenant_id ||
      !currentIncident?.sharepoint_location
    ) {
      // Clear the cache if tenant_id or sharepoint_location is missing
      queryClient.removeQueries({ queryKey: [keys.sharepointFiles] });
    }
    // No action needed if all required fields are present
  }, [currentIncident, queryClient]);

  // Optionally: Invalidate cache when currentIncident changes and has valid data
  useEffect(() => {
    if (
      currentIncident &&
      currentIncident?.tenant_id &&
      currentIncident?.sharepoint_location
    ) {
      queryClient.invalidateQueries({
        queryKey: [
          keys.sharepointFiles,
          currentIncident?.tenant_id,
          currentIncident?.sharepoint_location,
        ],
      });
    }
  }, [currentIncident, queryClient]);

  return useQuery({
    queryKey: [
      keys.sharepointFiles,
      currentIncident?.tenant_id,
      currentIncident?.sharepoint_location,
    ],
    queryFn: async () => {
      // This check is redundant now because the query is disabled if currentIncident is falsy
      // However, it's good to keep it for safety
      if (!currentIncident) {
        throw new Error('No incident selected');
      }

      // Check if tenant_id and sharepoint_location are present
      if (!currentIncident?.tenant_id || !currentIncident?.sharepoint_location) {
        // This condition should prevent the queryFn from running, but it's good to handle it
        throw new Error('Incomplete incident data');
      }

      const { data } = await axios.get(
        `${config.apiGateway.sharepoint_files}`,
        {
          params: {
            tenantId: currentIncident?.tenant_id,
            sharepointDriveId: currentIncident?.sharepoint_location,
          },
        }
      );
      return data;
    },
    retry: (_, error: any) => {
      const status = error?.response?.status;
      if (status === 404 || status === 500) {
        toast.error('Failed to fetch SharePoint files', toastConfig);
        return false;
      } else {
        return true;
      }
    },
    staleTime: 60000, // Cache results for 60 seconds
    enabled:
      !!currentIncident &&
      !!currentIncident.tenant_id &&
      !!currentIncident.sharepoint_location, // Only run the query if all required data is available
  });
};

export const useSyncSharepointFiles = () => {
  const group_guid = useSelector(selectGroupGuid);
  const queryClient = useQueryClient();
  const [isSyncing, setIsSyncing] = useState(false);

  const [sharepointSyncSession, setSharepointSyncSession] = useState(false);

  const currentIncident = useSelector(selectIncident);

  const fetchSharepointSyncSession = async (group_guid: UUID) => {
    const { data } = await axios.get(`${config.apiGateway.sharepoint_sync}`, {
      params: {
        group_guid,
      },
    });

    return data?.vector_store;
  };

  useEffect(() => {
    if (!isSyncing) {
      fetchSharepointSyncSession(group_guid)
        .then((session) => setSharepointSyncSession(session))
        .catch((e) => {
          toast.error('Failed to retrieve file storage session.');
        });
    }
  }, [group_guid, isSyncing]);

  const syncSharepointFiles = async (hard_sync = false) => {
    // Added hard_sync parameter
    setIsSyncing(true);
    try {
      const { data } = await axios.post(
        `${config.apiGateway.sharepoint_sync}`,
        {
          group_guid,
          tenantId: currentIncident?.tenant_id,
          sharepointDriveId: currentIncident?.sharepoint_location,
          hard_sync, // Include hard_sync in the request body
        }
      );
      const { sync_id, errMsg } = data;

      if (errMsg) {
        toast.error(errMsg, { ...toastConfig, autoClose: false });
      }

      // Start polling for sync status
      const pollSyncStatus = async () => {
        let errMsg = 'Pratus error: please contact support@disastertech.com';

        try {
          const { data } = await axios.get(
            `${config.apiGateway.sharepoint_sync}/status`,
            {
              params: { sync_id },
            }
          );
          if (data.status === 'Success') {
            toast.success('Files synced successfully.', toastConfig);
            // Invalidate queries to refresh data
            queryClient.invalidateQueries({ queryKey: [keys.sharepointFiles] });
            setIsSyncing(false);
          } else if (data.status === 'Error') {
            errMsg = data.errMsg ? data.errMsg : errMsg;
            toast.error(errMsg, { ...toastConfig, autoClose: false });
            setIsSyncing(false);
          } else if (data.status === 'SyncSessionNotFound') {
            toast.error(
              'File Sync Error: Please click ‘File Sync’ and try again',
              { ...toastConfig, autoClose: false }
            );
            setIsSyncing(false);
          } else {
            // Status is 'Init' or 'In Progress', continue polling
            setTimeout(pollSyncStatus, 2000); // Poll every 2 seconds
          }
        } catch (error) {
          toast.error(errMsg, { ...toastConfig, autoClose: false });
          setIsSyncing(false);
        }
      };

      // Start polling
      pollSyncStatus();
    } catch (error) {
      toast.error(
        `Sync Error: ${error}.  If this issue persists, please contact support@disastertech.com.`,
        { ...toastConfig, autoClose: false }
      );
      setIsSyncing(false);
    }
  };

  return {
    sharepointSyncSession,
    syncSharepointFiles,
    isSyncing,
  };
};
